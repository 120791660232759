import React from 'react';
import styled from 'styled-components';
import TabsPage from './components/TabsPage';

const AppContainer = styled.div`
  height: 800px;
  text-align: center;
  padding: 8px;

  @media only screen and (max-width: 600px) {
    height: 400px;
  }
`;
const Hr = styled.hr`
  border: 0;
  padding: 3px;
  background: linear-gradient(135deg, red, orange, green, blue, purple);
  mask-image: repeating-linear-gradient(
    135deg,
    #000 0px,
    #000 1px,
    transparent 1px,
    transparent 6px
  );
`;

const App = () => {
  return (
    <AppContainer>
      <TabsPage />
      <Hr />
    </AppContainer>
  );
};

export default App;
