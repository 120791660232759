import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';

const ScoreBarContainer = styled.div`
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const ScoreBar = () => {
  useEffect(() => {
    // 初始化echarts实例
    const chartDom = document.getElementById('score');
    const myChart = echarts.init(chartDom);

    // 设置图表配置项
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter: (value) => {
          if (!value) {
            return '-';
          } else {
            return value + '%';
          }
        },
      },
      legend: {
        data: ['债基', '美国', '香港', 'A股', '帮你投', 'BTC'],
      },
      grid: {
        left: '0%',
        right: '0%',
        bottom: '0%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        data: ['3-5', '3-27', '4-5', '4-27'],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: '债基',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: [0.7, 0.41, 1.33, 2.06],
        },
        {
          name: '美国',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: [1.17, 1.3, -0.19, -0.3],
        },
        {
          name: '香港',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: [0.97, 1.3, 0.73, 0.62],
        },
        {
          name: 'A股',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: [-3.24, 4.32, 12.46, 2.36],
        },
        {
          name: '帮你投',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'inside',
          },
          emphasis: {
            focus: 'series',
          },
          data: [1.26, -0.14, 0.33, 0.05],
        },
        {
          name: 'BTC',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'inside',
          },
          emphasis: {
            focus: 'series',
          },
          data: [2.57, 0.48, -0.31, -0.24],
        },
      ],
    };

    // 使用setOption方法设置图表配置项
    myChart.setOption(option);

    // 返回一个清理函数以销毁echarts实例
    return () => {
      myChart.dispose();
    };
  }, []);

  return <ScoreBarContainer id='score' />;
};

export default ScoreBar;
