import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PieChart from './PieChart';
import BarChart from './BarChart';
import PercentColumn from './PercentColumn';
import data from '../data/change.json';
import ScoreBar from './ScoreBar';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabsPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='basic tabs example'
        >
          <Tab label='currency' {...a11yProps(0)} />
          <Tab label='risk' {...a11yProps(1)} />
          <Tab label='change 1' {...a11yProps(2)} />
          <Tab label='change 2' {...a11yProps(3)} />
          <Tab label='portfolio' {...a11yProps(4)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <PieChart />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <BarChart />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <PercentColumn data={data.currencyType} id='currencyType' />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <PercentColumn data={data.riskType} id='riskType' />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <ScoreBar />
      </CustomTabPanel>
    </Box>
  );
};

export default TabsPage;
