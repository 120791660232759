import React, { useEffect, useMemo } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';

const PercentColumnContainer = styled.div`
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const PercentColumn = (props) => {
  const { data, id } = props;
  const date = useMemo(() => {
    return [...new Set(data.map((item) => item.date))];
  }, [data]);
  const type = [...new Set(data.map((item) => item.type))].reverse();

  // 处理数据
  const typeData = {}; // 创建一个空对象，用于按照type分类存储数据
  data.forEach((item) => {
    if (!typeData[item.type]) {
      typeData[item.type] = [];
    }
    typeData[item.type].push(item.value); // 遍历数据，按照type分类存储value
  });
  const rawData = type.map((v) => typeData[v]); // 将typeData转换为要求的格式

  // 计算总和
  const totalData = useMemo(() => {
    const result = [];
    for (let i = 0; i < rawData[0].length; ++i) {
      let sum = 0;
      for (let j = 0; j < rawData.length; ++j) {
        sum += rawData[j][i];
      }
      result.push(Number(sum.toFixed(2))); // 将计算得到的总和保留两位小数并转换为数字类型
    }
    return result;
  }, [rawData]);

  // 归一化函数
  const calculateValue = (d, did, totalData) => {
    const theOne = Math.max(0, d / totalData[did] - 0.0001);
    return theOne;
  };

  useEffect(() => {
    const chartDom = document.getElementById(id);
    const myChart = echarts.init(chartDom);
    const grid = {
      left: '10%',
      right: '2%',
      top: '12%',
      bottom: '4%',
    };

    const series = type.map((name, sid) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
        label: {
          show: true,
          formatter: (params) => Math.round(params.value * 1000) / 10 + '%',
        },
        data: rawData[sid].map((d, did) => calculateValue(d, did, totalData)),
      };
    });

    const option = {
      legend: {
        selectedMode: true,
      },
      grid,
      yAxis: {
        type: 'value',
      },
      xAxis: {
        type: 'category',
        data: date,
      },
      series,
    };
    option && myChart.setOption(option);
  }, [id, date, rawData, totalData, type]);

  return <PercentColumnContainer id={id} />;
};

export default PercentColumn;
