import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';
import data from '../data/weight.json';

const BarChartContainer = styled.div`
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const originalData = data.bar;
const sortedData = originalData.reduce((acc, curr) => {
  if (!acc[curr.name]) {
    acc[curr.name] = {};
  }
  acc[curr.name][curr.cat] = curr.value;
  return acc;
}, {});

const dataL = [
  sortedData['虚拟币']?.['低风险'] || '',
  sortedData['日元']?.['低风险'] || '',
  sortedData['港币']?.['低风险'] || '',
  sortedData['美元']?.['低风险'] || '',
  sortedData['人民币']?.['低风险'] || '',
];
const dataM = [
  sortedData['虚拟币']?.['中风险'] || '',
  sortedData['日元']?.['中风险'] || '',
  sortedData['港币']?.['中风险'] || '',
  sortedData['美元']?.['中风险'] || '',
  sortedData['人民币']?.['中风险'] || '',
];
const dataH = [
  sortedData['虚拟币']?.['高风险'] || '',
  sortedData['日元']?.['高风险'] || '',
  sortedData['港币']?.['高风险'] || '',
  sortedData['美元']?.['高风险'] || '',
  sortedData['人民币']?.['高风险'] || '',
];

const BarChart = () => {
  const chartRef = useRef(null);
  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      grid: {
        left: '0%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        data: ['虚拟币', '日元', '港币', '美元', '人民币'],
      },
      series: [
        {
          name: '低风险',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: dataL,
        },
        {
          name: '中风险',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            position: 'bottom',
          },
          emphasis: {
            focus: 'series',
          },
          data: dataM,
        },
        {
          name: '高风险',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: dataH,
        },
      ],
    };
    myChart.setOption(option);
    return () => {
      myChart.dispose();
    };
  }, []);

  return <BarChartContainer ref={chartRef} />;
};

export default BarChart;
