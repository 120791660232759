import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';
import data from '../data/weight.json'; // 引入 JSON 文件

const PieChartContainer = styled.div`
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const PieChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('pie');
    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
      },
      series: [
        {
          // name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data.pie,
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <PieChartContainer id='pie' />;
};

export default PieChart;
